import React from 'react';
import Nav from './Nav';
import Footer from './Footer';

import 'modern-normalize/modern-normalize.css';
import './globalStyles.css';
import InfoBar from './infoBar';

const Layout = ({ children, contact }) => {
  return (
    <div style={{ overflow: 'hidden' }}>
      <InfoBar contact={contact} />
      <Nav />
      {children}
      <Footer />
    </div>
  );
};

export default Layout;
