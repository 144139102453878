import React from 'react';
import Image from './Image';
import './BackgroundImage.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

const BackgroundSlider = ({ images }) => {
  return (
    <Carousel
      transitionTime={500}
      infiniteLoop
      showStatus={false}
      showThumbs={false}
      autoPlay
    >
      {images.map((image, index) => {
        return (
          <figure key={index} className="image">
            <div>
              <Image resolutions="small" src={image.image} alt={image.alt} />
            </div>
            {image.title && <figcaption>{image.title}hello</figcaption>}
          </figure>
        );
      })}
    </Carousel>
  );
};

export default BackgroundSlider;
