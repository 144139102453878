import React, { useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { ENDPOINT } from '../../config';

import './Form.css';
import Spinner from '../components/Spinner';

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  type: 'Type of Enquiry',
  message: '',
};

const Form = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [formData, setFormData] = useState(initialState);
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);

  const formHandler = ({ target: { name, value } }) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async e => {
    e.preventDefault();

    setSent(false);
    setSending(true);
    setError(false);

    try {
      if (!executeRecaptcha) throw new Error('Failed to send enquiry');
      const result = await executeRecaptcha('contact');
      const response = await fetch(`${ENDPOINT}/contact`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({ formData, token: result }),
      });
      const data = await response.json();
      if (!data.ok) throw new Error('Failed');

      setFormData(initialState);
      setSent(true);
    } catch (error) {
      setSent(false);
      setError(true);
    }

    setSending(false);
  };

  return (
    <form className="Form" onSubmit={handleSubmit}>
      <div className="Form--Group">
        <label className="Form--Label">
          <input
            className="Form--Input Form--InputText"
            type="text"
            placeholder="First Name"
            name="firstName"
            value={formData.firstName}
            onChange={formHandler}
            required
          />
          <span>First Name</span>
        </label>
        <label className="Form--Label">
          <input
            className="Form--Input Form--InputText"
            type="text"
            placeholder="Last Name"
            name="lastName"
            value={formData.lastName}
            onChange={formHandler}
            required
          />
          <span>Last Name</span>
        </label>
      </div>

      <label className="Form--Label">
        <input
          className="Form--Input Form--InputText"
          type="email"
          placeholder="Email"
          name="email"
          value={formData.email}
          onChange={formHandler}
          required
        />
        <span>Email Address</span>
      </label>
      <label className="Form--Label">
        <input
          className="Form--Input Form--InputText"
          type="number"
          placeholder="Phone"
          name="phone"
          value={formData.phone}
          onChange={formHandler}
          required
        />
        <span>Telephone</span>
      </label>
      <label className="Form--Label has-arrow">
        <select
          className="Form--Input Form--Select"
          name="type"
          value={formData.type}
          onChange={formHandler}
          required
        >
          <option disabled hidden>
            Type of Enquiry
          </option>
          <option>Manual Lesson</option>
          <option>Automatic Lesson</option>
          <option>Block/Bulk Booking</option>
          <option>Intensive Course</option>
          <option>Refresher Lessons</option>
          <option>Booster Lessons</option>
          <option>Confidence Course</option>
          <option>Motorway Lessons</option>
          <option>Pass Plus</option>
          <option>Other</option>
        </select>
      </label>
      <label className="Form--Label">
        <textarea
          className="Form--Input Form--Textarea Form--InputText"
          placeholder="Message"
          name="message"
          value={formData.message}
          onChange={formHandler}
          rows="10"
          required
        />
        <span>Message</span>
      </label>

      <div className="Form--SubmitContainer">
        <input
          className="Button Form--SubmitButton"
          type="submit"
          value="Enquire"
        />
        <div className="spinnerArea">
          {sending && (
            <>
              <Spinner /> <span>Sending...</span>
            </>
          )}
          {sent && <span>Message sent</span>}
          {error && <span>Error, Please try again.</span>}
        </div>
      </div>
    </form>
  );
};

export default Form;
