import React from 'react';
import PriceItem from './PriceItem';

const Pricing = ({ priceItems }) => {
  return (
    <div>
      {priceItems.map(({ title, body, image }, index) => (
        <PriceItem
          key={title}
          title={title}
          body={body}
          image={image}
          right={index % 2 !== 0}
        />
      ))}
    </div>
  );
};

export default Pricing;
