import React from 'react';
import './UrgentBanner.css';

const UrgentBanner = ({ urgentBanner }) => {
  return (
    urgentBanner &&
    urgentBanner.show && (
      <a target="_blank" rel="noreferrer" href={urgentBanner.link}>
        <div className="UrgentContainer">
          <p>{urgentBanner.text}</p>
        </div>
      </a>
    )
  );
};

export default UrgentBanner;
