import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({ seo }) => {
  if (seo) {
    const {
      siteTitle,
      siteDescription,
      siteUrl,
      socialMediaCard: { image },
    } = seo;

    return (
      <Helmet title={siteTitle}>
        {siteDescription && (
          <meta name="description" content={siteDescription} />
        )}
        {image && <meta name="image" content={image} />}
        {siteUrl && <meta property="og:url" content={siteUrl} />}
        <meta property="og:type" content="website" />
        {siteTitle && <meta property="og:title" content={siteTitle} />}
        {siteDescription && (
          <meta property="og:description" content={siteDescription} />
        )}
        {image && <meta property="og:image" content={image} />}
        <meta property="og:locale" content="en_GB" />
        {siteTitle && <meta property="og:site_name" content={siteTitle} />}
        <meta name="twitter:card" content="summary_large_image" />
        {siteTitle && <meta name="twitter:title" content={siteTitle} />}
        {siteDescription && (
          <meta name="twitter:description" content={siteDescription} />
        )}
        {image && <meta name="twitter:image" content={image} />}
      </Helmet>
    );
  } else {
    return null;
  }
};

export default SEO;
