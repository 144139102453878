import React from 'react'
import './Links.css'

const Links = ({ links }) => {
  return (
    <div className="Links_Container">
      {links.map(({ name, link }) => (
        <div key={name} className="Link">
          <a href={link}>{name}</a>
        </div>
      ))}
    </div>
  )
}

export default Links
