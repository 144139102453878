import React from 'react'
import { graphql } from 'gatsby'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { RECAPTCHA_KEY } from '../../config'
import Img from 'gatsby-image'

import PageHeader from '../components/PageHeader'
import Content from '../components/Content'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import LearnerHeading from '../components/LearnerHeading'
import Links from '../components/Links'
import Cards from '../components/cards'
import Pricing from '../components/Pricing'
import Contact from '../components/Contact'
import BackgroundSlider from '../components/BackgroundSlider'

export const HomePageTemplate = ({
  seo,
  title,
  subtitle,
  featuredImage,
  urgentBanner,
  aboutUs,
  cards,
  pricing,
  referral,
  testimonialGallery,
  infoBanner,
  contact
}) => (
  <>
    <SEO seo={seo} />
    <main className="Home">
      <PageHeader
        large
        title={title}
        subtitle={subtitle}
        backgroundImage={featuredImage}
        urgentBanner={urgentBanner}
      />
      {aboutUs && (
        <section className="section" id="aboutUs">
          <div className="container large-text">
            <LearnerHeading heading={aboutUs.title} />
            {aboutUs.body && <Content src={aboutUs.body} />}
          </div>
        </section>
      )}
      {cards && <Cards cards={cards} />}

      {pricing && (
        <section className="section" id="pricing">
          <div className="container">
            <LearnerHeading heading={pricing.title} />
            <Pricing priceItems={pricing.priceItems} />
          </div>
        </section>
      )}

      {referral && (
        <>
          <div id="offers" />
          <a href="https://www.myfirstuk.com" rel="noreferrer" target="_blank">
            <Img fluid={referral} />
          </a>
        </>
      )}

      {testimonialGallery && (
        <section className="section">
          <div className="container">
            <LearnerHeading heading={testimonialGallery.galleryTitle} />
            <div className="ImageSliderArea">
              <BackgroundSlider images={testimonialGallery.galleryImages} />
              <div className="ImageSliderText">
                <Content src={testimonialGallery.galleryText} />
              </div>
            </div>
          </div>
        </section>
      )}

      {infoBanner && (
        <div className="informationBanner">
          <section className="section">
            <div className="container">
              <h2>{infoBanner.title}</h2>
              <Links links={infoBanner.links} />
            </div>
          </section>
        </div>
      )}

      {/* {offers && <Cards cards={offers} clickable />} */}

      {contact && (
        <section className="section" id="contact">
          <div className="container">
            <LearnerHeading heading={contact.title} />
            <Content src={contact.body} />
            <Contact
              address={contact.address}
              phone={contact.phone}
              email={contact.email}
              facebook={contact.facebook}
            />
          </div>
        </section>
      )}
    </main>
  </>
)

const HomePage = ({ data: { homePage, seoSettings, referral } }) => (
  <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
    <Layout
      meta={homePage.frontmatter.meta || false}
      contact={homePage.frontmatter.contact}
    >
      <HomePageTemplate
        {...homePage}
        {...homePage.frontmatter}
        seo={seoSettings.nodes[0]}
        referral={referral.nodes[0].childImageSharp.fluid}
      />
    </Layout>
  </GoogleReCaptchaProvider>
)

export default HomePage

export const pageQuery = graphql`
  {
    seoSettings: allSettingsYaml {
      nodes {
        siteTitle
        siteDescription
        siteUrl
        socialMediaCard {
          image
        }
        googleTrackingId
      }
    }

    referral: allFile(filter: { name: { eq: "insurance" } }) {
      nodes {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }

    homePage: markdownRemark(frontmatter: { template: { eq: "HomePage" } }) {
      frontmatter {
        title
        subtitle
        featuredImage
        urgentBanner {
          show
          text
          link
        }
        aboutUs {
          title
          body
        }
        cards {
          cardOne {
            title
            body
            cardImage {
              alt
              image
            }
          }
          cardTwo {
            title
            body
            cardImage {
              alt
              image
            }
          }
          cardThree {
            title
            body
            cardImage {
              alt
              image
            }
          }
          cardFour {
            title
            body
            cardImage {
              alt
              image
            }
          }
        }
        pricing {
          priceItems {
            body
            image
            title
          }
          title
        }
        testimonialGallery {
          galleryImages {
            alt
            image
          }
          galleryTitle
          galleryText
        }
        infoBanner {
          title
          links {
            name
            link
          }
        }
        contact {
          title
          body
          address
          phone
          email
          facebook
        }
      }
    }
  }
`
