import React from 'react'
import Content from './Content'
import './PriceItem.css'

const PriceItem = ({ title, body, image, right }) => {
  return (
    <div className={`PriceItemContainer ${right && 'right'}`}>
      {title && <h3>{title}</h3>}
      <div className={`PriceItemContentContainer ${right && 'right'}`}>
        <div>
          <img src={image} alt="Car" />
        </div>
        <div className="text">
          <Content src={body} />
        </div>
      </div>
    </div>
  )
}

export default PriceItem
