import React from 'react';
import Logo from './Logo';

const LearnerHeading = ({ heading }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: '1rem',
      }}
    >
      <Logo />
      <h2 style={{ padding: '1rem 0 0 0.6rem' }}>{heading}</h2>
    </div>
  );
};

export default LearnerHeading;
