import React, { useState } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Link } from 'gatsby';
import { Menu, X } from 'react-feather';
import Logo from './Logo';

import './Nav.css';

const NAV_ITEMS = [
  { label: 'Home', anchor: 'home' },
  { label: 'About us', anchor: 'aboutUs' },
  { label: 'Pricing', anchor: 'pricing' },
  { label: 'Offers', anchor: 'offers' },
  { label: 'Contact', anchor: 'contact' },
];

const Navigation = () => {
  const [active, setActive] = useState(false);

  const handleMenuToggle = () => {
    setActive(prevState => !prevState);
  };

  const handleLinkClick = () => active && handleMenuToggle();

  return (
    <nav className={`Nav ${active ? 'Nav-active' : ''}`}>
      <div className="Nav--Container container">
        <Link to="/">
          <Logo />
        </Link>
        <div className="Nav--Links">
          {NAV_ITEMS.map(({ label, anchor }) => (
            <div onClick={handleLinkClick} key={label}>
              <AnchorLink href={`#${anchor}`} className="NavLink">
                {label}
              </AnchorLink>
            </div>
          ))}
        </div>
        <button
          className="Button-blank Nav--MenuButton"
          onClick={handleMenuToggle}
        >
          {active ? <X /> : <Menu />}
        </button>
      </div>
    </nav>
  );
};

export default Navigation;
