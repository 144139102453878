import React from 'react';

const GoogleMap = () => {
  return (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d40097.38626397547!2d1.134099034068784!3d51.088408519432335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47deb815138b2c8f%3A0xa514d67a986aff93!2sFolkestone!5e0!3m2!1sen!2suk!4v1593421273744!5m2!1sen!2suk"
      width="100%"
      height="300"
      frameBorder="0"
      style={{ border: 0 }}
      allowFullScreen=""
      tabIndex="0"
      title="Google map"
    />
  );
};

export default GoogleMap;
