import React from 'react';
import './card.css';
import Content from './Content';

const Card = ({ background, alt, title, body, clickable, link }) => {
  return (
    <div
      className={`Card ${clickable && 'clickable'}`}
      style={{
        background: `url("${background}") no-repeat center`,
        backgroundSize: 'cover',
      }}
      title={alt}
    >
      {clickable ? (
        <a href={link} rel="noreferrer" target="_blank">
          <div className="CardOverlay">
            <div className="CardContent">
              {title && <h2>{title}</h2>}
              {body && <p>{body}</p>}
            </div>
          </div>
        </a>
      ) : (
        <div className="CardOverlay">
          <div className="CardContent">
            {title && <h2>{title}</h2>}
            {body && <Content src={body} />}
          </div>
        </div>
      )}
    </div>
  );
};

export default Card;
