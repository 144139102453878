import React from 'react'
import { MapPin, Smartphone, Mail, Facebook } from 'react-feather'

import FormSimpleAjax from '../components/FormSimpleAjax'
import GoogleMap from '../components/GoogleMap'
import './Contact.css'

export const Contact = ({ address, phone, email, locations, facebook }) => (
  <>
    <div className="Contact--Section1--Container">
      <div>
        <div className="Contact--Details">
          {address && (
            <a
              className="Contact--Details--Item"
              href={`https://www.google.com.au/maps/search/${encodeURI(
                address
              )}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <MapPin /> {address}
            </a>
          )}
          {phone && (
            <a className="Contact--Details--Item" href={`tel:${phone}`}>
              <Smartphone /> {phone}
            </a>
          )}
          {email && (
            <a className="Contact--Details--Item" href={`mailto:${email}`}>
              <Mail /> {email}
            </a>
          )}
          {facebook && (
            <a
              className="Contact--Details--Item"
              href={`https://www.facebook.com/${facebook}`}
              target="_blank"
              rel="noreferrer"
            >
              <Facebook /> {facebook}
            </a>
          )}
        </div>
        <GoogleMap locations={locations} />
      </div>
      <div>
        <FormSimpleAjax name="Simple Form Ajax" />
      </div>
    </div>
  </>
)

export default Contact
