import React from 'react'

import Image from './Image'
import Content from './Content'
import './PageHeader.css'
import UrgentBanner from './UrgentBanner'

const PageHeader = ({
  urgentBanner,
  title,
  subtitle,
  backgroundImage,
  large,
  className = ''
}) => {
  if (large) className += ' PageHeader-large'
  return (
    <div className={`PageHeader relative ${className}`}>
      {backgroundImage && (
        <Image
          background
          resolutions="large"
          src={backgroundImage}
          alt={title}
          size="cover"
        />
      )}
      {urgentBanner && <UrgentBanner urgentBanner={urgentBanner} />}
      <div className="container relative">
        <h1 className="PageHeader--Title">{title}</h1>
        {subtitle && (
          <Content className="PageHeader--Subtitle" src={subtitle} />
        )}
      </div>
    </div>
  )
}

export default PageHeader
