import React from 'react';
import Card from './card';

import './cards.css';

const Cards = ({ cards, clickable }) => {
  return (
    <div className="CardsContainer">
      <div className="Cards">
        {Object.values(cards).map(
          ({ title, body, cardImage: { image, alt, link } }, index) => (
            <Card
              key={`${title}_${index}`}
              background={image}
              alt={alt}
              title={title}
              body={body}
              clickable={clickable}
              link={link}
            />
          ),
        )}
      </div>
    </div>
  );
};

export default Cards;
