import React from 'react';
import { Facebook, Smartphone, Mail } from 'react-feather';

import './infoBar.css';

const InfoBar = ({ contact }) => {
  if (contact) {
    return (
      <div className="infoBar">
        {contact.phone && (
          <a href={`tel:${contact.phone}`}>
            <Smartphone />
            <p>{contact.phone}</p>
          </a>
        )}

        {contact.email && (
          <a href={`mailto:${contact.email}`}>
            <Mail />
            <p>{contact.email}</p>
          </a>
        )}

        {contact.facebook && (
          <a
            href={`https://www.facebook.com/${contact.facebook}`}
            target="_blank"
            rel="noreferrer"
            className="removable"
          >
            <Facebook />
            <p>{contact.facebook}</p>
          </a>
        )}
      </div>
    );
  } else {
    return null;
  }
};

export default InfoBar;
